@if (isReady) {
    <mat-drawer-container class="example-container" [hasBackdrop]="true">
        <mat-drawer #drawer mode="over">
            @if (drawerTemplate) {
                <ng-container *ngTemplateOutlet="drawerTemplate"></ng-container>
            }
        </mat-drawer>
        <mat-drawer-content>
            <div class="page-container">
                <!-- set tags css when user is logged  -->
                @if (!isBlankPage()) {
                    <app-responsive-navigation-modules />
                    <app-tags />
                }

                @if (currentRelativeUrl.startsWith('/sd1') && isLegacyAngularReady.value) {
                    <div class="sd1-route sd2 ">
                        <div
                            [ngClass]="{'blank-page': isBlankPage() }"
                            [class.dark-theme]="themeService.isDark()"
                            [class.card-borderd-theme]="themeService.isCardBorder()"
                            [class.card-border-radius]="themeService.isCardBorderRadius()"
                        >
                            @if (!isBlankPage() && !isPopup) {
                                <app-aside-menu />
                            }
                            <div
                                class="main-content d-flex flex-column"
                                [ngClass]="{'active': isToggled}"
                                [class.hide-sidebar]="themeService.isHideSidebar()"
                            >
                                @if ((!isPopup && isHome() && isMobile) || (!isMobile && !isPopup)) {
                                    <app-header />
                                    <app-g-search-panel />
                                }

                                @if (isMobile && !isBlankPage() && !isHome()) {
                                    <app-responsive-navigation-header />
                                }

                                @if (isMobile && !isBlankPage()) {
                                    <app-responsive-navigation-footer />
                                }
                            </div>

                        </div>
                    </div>
                }

                @if (!currentRelativeUrl.startsWith('/sd1')) {
                    <div class="sd2">
                        <div
                            [ngClass]="{'blank-page': isBlankPage() }"
                            [class.dark-theme]="themeService.isDark()"
                            [class.card-borderd-theme]="themeService.isCardBorder()"
                            [class.card-border-radius]="themeService.isCardBorderRadius()"
                        >
                            @if (!isBlankPage()) {
                                <app-aside-menu />
                            }

                            <div
                                class="main-content d-flex flex-column"
                                [ngClass]="{'active': isToggled}"
                                [class.hide-sidebar]="themeService.isHideSidebar()"
                            >
                                @if ((!isMobile || isHome()) && !isBlankPage() && !isPopup) {
                                    <app-header></app-header>
                                }

                                @if (isMobile && !isBlankPage() && !isHome() && !isPopup) {
                                    <app-responsive-navigation-header />
                                }

                                @if (!isBlankPage()) {
                                    <app-g-search-panel />
                                }
                                <div [ngClass]="{ home: isHome(), 'a-router-outlet': !isBlankPage() }">
                                    <router-outlet></router-outlet>
                                </div>
                                @if (isMobile && !isBlankPage()) {
                                    <app-responsive-navigation-footer />
                                }
                                <div class="flex-grow-1"></div>
                            </div>
                        </div>

                    </div>
                }
            </div>

        </mat-drawer-content>
    </mat-drawer-container>

}
