import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ObjectUtilsService {
    recursiveSort(input) {
        if (Array.isArray(input)) {
            return input.sort();
        } else if (typeof input === 'object' && input !== null) {
            const sortedObject = {};
            Object.keys(input)
                .sort()
                .forEach(key => {
                    sortedObject[key] = this.recursiveSort(input[key]);
                });
            return sortedObject;
        }
        return input;
    }

    getAllPrototypeEntries(obj: any): [string, Function][] {
        let proto = Object.getPrototypeOf(obj);
        const prototypeEntries: [string, Function][] = [];

        while (proto && proto !== Object.prototype) {
            Object.getOwnPropertyNames(proto)
                .filter(name => typeof obj[name] === 'function')
                .forEach(name => prototypeEntries.push([name, obj[name]]));

            proto = Object.getPrototypeOf(proto);
        }

        return prototypeEntries;
    }

    deepCopy<T>(obj: T): T {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            const copy: Array<T> = [];
            for (let i = 0; i < obj.length; i++) {
                copy[i] = this.deepCopy(obj[i]);
            }
            return copy as unknown as T;
        }

        const entries = Object.entries(obj);
        const prototypeEntries = this.getAllPrototypeEntries(obj);

        const allEntries = [...entries, ...prototypeEntries];

        const copy: { [key: string]: T } = {};
        for (const [key, value] of allEntries) {
            copy[key] = this.deepCopy(value);
        }
        return copy as T;
    }
}
