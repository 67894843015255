import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
    providedIn: 'root',
})
export class BreakpointService {
    protected isMobile = new BehaviorSubject<boolean>(false);
    protected isDesktop = new BehaviorSubject<boolean>(true);

    public isMobile$ = this.isMobile.asObservable();
    public isDesktop$ = this.isDesktop.asObservable();

    constructor(protected bk: BreakpointObserver) {}

    init() {
        /* 1200 is same as aside menu hide */
        this.bk.observe('(max-width: 1200px)').subscribe(value => {
            this.isMobile.next(value.matches);
            this.isDesktop.next(!value.matches);
        });
    }
}
