import { inject } from '@angular/core';
import { Router } from '@angular/router';

export class GeneratePopupUrlService {
    router = inject(Router);

    constructor(public popupOutletNumber: null | number) {}

    public getUrl(url: string): Array<unknown> {
        if (!this.popupOutletNumber) {
            this.popupOutletNumber = 0;
        }

        const urlTree = this.router.parseUrl(this.router.url);
        const currentOutlets = urlTree.root.children as { [outletName: string]: any };

        const outlets: { [outletName: string]: string | null } = {};

        for (const [outletName, segmentGroup] of Object.entries(currentOutlets)) {
            outlets[outletName] = segmentGroup.toString();
        }

        const newOutletName = `p${this.popupOutletNumber + 1}`;
        outlets[newOutletName] = url;

        return ['', { outlets }];
    }
}
