import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MenuToggleService } from '../../services/menu-toggle.service';
import { AuthService, CurrentSession } from '@auth/services/auth.service';
import { Router } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { GSearchService } from '../../../g-search/services/g-search.service';
import { Translations } from '@translation-keys';
import { TranslationService } from '../../../core/services/translation.service';
import { debounceTime, Subject } from 'rxjs';
import { DocumentationRole, MenuModule, MenuService } from '../../services/menu.service';
import { BreakpointService } from '../../services/breakpoint.service';
import { ResponsiveNavigationService } from '../responsive-navigation/services/responsive-navigation.service';
import { ThemeService } from '../../services/theme.service';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { AuthStorageService } from '@auth/services/auth-storage.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    translations = Translations;
    lang = 'fr';
    currentModule: MenuModule | null = null;
    currentSession: CurrentSession = {
        fullName: null,
        avatarUrl: null,
        firstName: null,
        hasLegacyAngular: true,
        id: 0,
        languagePreference: 'fr',
        acl: {
            roles: [],
            abilities: {},
        },
    };
    search = '';
    isMobile = false;

    placeholder = this.translations.core.modules.header.commons.labels['press/ToSearch'];

    languages = [
        {
            id: 'fr',
            label: 'French',
        },
        {
            id: 'en',
            label: 'English',
        },
    ];

    isSticky = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

    @HostListener('window:keyup', ['$event'])
    onKeyUp(event: KeyboardEvent) {
        if (event.key === '/') {
            this.searchInput.nativeElement.focus();
            this.searchClick();
        }
    }

    isToggled = false;
    shouldDisplayMenu = false;
    searchSubject = new Subject<string>();
    documentations: DocumentationRole[] = [];

    constructor(
        private toggleService: MenuToggleService,
        public themeService: ThemeService,
        public router: Router,
        public authService: AuthService,
        public upgrade: UpgradeModule,
        public gSearchService: GSearchService,
        private changeDetector: ChangeDetectorRef,
        private translationService: TranslationService,
        private menuService: MenuService,
        public breakpointService: BreakpointService,
        public rns: ResponsiveNavigationService,
        public matDrawer: MatDrawerContainer,
        public authStorageService: AuthStorageService,
    ) {
        const lang = this.authStorageService.getItem('lang');
        if (lang && this.languages.some(language => language.id === lang)) {
            this.lang = lang;
        }

        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });

        if (this.authService.currentSessionData) {
            this.currentSession = this.authService.currentSessionData;
            this.addDevLanguageToDevelopers(this.currentSession.acl.roles);
        }

        /* should get data when some user data is changed */
        this.authService.currentSession.subscribe(currentSession => {
            this.currentSession = currentSession;
            this.addDevLanguageToDevelopers(currentSession.acl.roles);
        });

        this.searchSubject.pipe(debounceTime(200)).subscribe(searchTerm => {
            this.gSearchService.searchChange(searchTerm);
        });
    }

    ngOnInit() {
        this.getDocumentations();

        this.gSearchService.isMenuRendering$.subscribe(status => {
            this.shouldDisplayMenu = status;
            this.changeDetector.detectChanges();
        });

        this.breakpointService.isMobile$.subscribe(status => {
            this.isMobile = status;
            this.placeholder = status
                ? this.translations.core.modules.header.commons.labels.searchFieldPlaceholderMobile
                : this.translations.core.modules.header.commons.labels.searchFieldPlaceholderDesktop;
        });

        this.rns.currentModule$.subscribe(currentModule => {
            this.currentModule = currentModule;
        });
    }

    isHome() {
        let url = this.router.url;

        if (url === '' || url === '/') {
            url = window.location.pathname;
        }
        return url === '/home';
    }

    toggle() {
        this.toggleService.toggle();
    }

    changeLanguage(lang: string) {
        const selectedLanguage = this.languages.find(language => lang === language.id);
        this.lang = selectedLanguage?.id ?? 'fr';
        this.authStorageService.setItem('lang', this.lang);
        const $translate = this.upgrade.$injector.get('$translate');
        $translate.use(this.lang);
        this.translationService.setLanguage(this.lang);
        this.getDocumentations();
    }

    getDocumentations() {
        this.menuService.getDocumentationRoles().subscribe(documentations => {
            this.documentations = documentations;
        });
    }

    logout() {
        this.authService.logout().then(() => {
            localStorage.removeItem('documentation_roles');
            localStorage.removeItem('AppAcl');
            localStorage.removeItem('satellizer_token');
            localStorage.removeItem('token');
            sessionStorage.clear();
            this.router.navigate(['/sign-in']).then();
        });
    }

    searchChange($event: string) {
        this.searchSubject.next($event);
    }

    closeMenu() {
        this.gSearchService.close();
    }

    searchClick() {
        const currentSearchValue = this.gSearchService.getCurrentSearchValue();
        if (!this.shouldDisplayMenu && currentSearchValue !== '') {
            this.gSearchService.searchChange(currentSearchValue);
        }
    }

    addDevLanguageToDevelopers(roles: string[]): void {
        const isDeveloper = roles.some(role => role === 'developer');
        if (isDeveloper) {
            this.languages.push({
                id: 'dev',
                label: 'Developer',
            });
        }
    }

    getStateAsUrl(state: string, debug = false) {
        const $state = this.upgrade.$injector.get('$state');
        if (debug) {
            // console.log(state, $state.href(state))
        }
        return $state.href(state);
    }

    openModules() {
        if (window.location.pathname.includes('sd1')) {
            const item = document.getElementsByClassName('new-angular-content').item(0);
            if (item) {
                item.classList.add('full-size-modules');
            }
        }

        this.rns.openModulesDrawer();
        if (this.matDrawer._drawers.toArray().length > 0) {
            this.matDrawer._drawers.toArray()[0].closedStart.subscribe(() => {
                if (window.location.pathname.includes('sd1')) {
                    const item = document.getElementsByClassName('new-angular-content').item(0);
                    if (item) {
                        item.classList.remove('full-size-modules');
                    }
                }
            });
        }
    }
}
