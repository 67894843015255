import { Injectable } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { WebsocketService } from '../../websocket/services/websocket.service';
import { HttpClient } from '@angular/common/http';
import {
    Notifications,
    NotificationsAccounting,
    NotificationsCustomers,
    NotificationsEmailSms,
    NotificationsHome,
    NotificationsResponse,
    NotificationsResponseAccounting,
    NotificationsResponseCustomer,
    NotificationsResponseEmailSms,
    NotificationsResponseHome,
} from '../types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    blankValues: Notifications = {
        accounting: 0,
        accountingOperations: 0,
        accountingOperationsPayorder: 0,
        accountingOperationsPayorderApproved: 0,
        accountingOperationsPayorderExported: 0,
        accountingOperationsPayorderPending: 0,
        accountingOperationsPayreq: 0,
        accountingOperationsPayreqPending: 0,
        accountingOperationsSales: 0,
        accountingOperationsSalesUnidentifiedPayments: 0,
        accountingOperationsSalesUninvoicedItems: 0,
        customers: 0,
        customersSimilarCustomers: 0,
        emailSmsAutoresponderHistoryErrors: 0,
        emailSmsAutoresponderModerationMessages: 0,
        emailSmsAutoresponder: 0,
        emailSms: 0,
        home: 0,
        homePendingTasks: 0,
    };

    private notifications = new BehaviorSubject<Notifications>(this.blankValues);

    constructor(public authService: AuthService, public ws: WebsocketService, public http: HttpClient) {}

    get notifications$() {
        return this.notifications.asObservable();
    }

    public listen() {
        this.authService.currentSession.subscribe(data => {
            const channel = 'notifications-user-' + data.id;

            if (data.websocket) {
                this.ws.createConnection(data.websocket);
            }

            window['Echo']
                .private(channel)
                .on('pusher:subscription_succeeded', () => this.forceNotificationUpdate())
                .listen('UpdatedNotifications', e => {
                    const notificationsResponse = e.notifications as NotificationsResponse;

                    const accounting = this.mapAccountingNotifications(notificationsResponse.accounting);
                    const customer = this.mapCustomersNotifications(notificationsResponse.customers);
                    const home = this.mapHomeNotifications(notificationsResponse.home);
                    const emailSms = this.mapEmailSmsNotifications(notificationsResponse.email_sms);

                    const notifications: Notifications = {
                        ...accounting,
                        ...customer,
                        ...home,
                        ...emailSms,
                    };

                    this.notifications.next(notifications);

                    /* sd1 pages */
                    window['notifications'] = notifications;
                });
        });
    }

    public forceNotificationUpdate() {
        this.http.get('/api/schooldrive/legacy/api/force-notifications-update').subscribe(() => {
            console.log('Requesting notifications update after connection');
        });
    }

    private mapAccountingNotifications(accounting: NotificationsResponseAccounting): NotificationsAccounting {
        const salesTotal = (accounting?.sales?.unidentifiedPayments ?? 0) + (accounting?.sales?.uninvoicedItems ?? 0);
        const payreqTotal = accounting?.payreq?.pending ?? 0;
        const payorderTotal =
            (accounting?.payorder?.pending ?? 0) +
            (accounting?.payorder?.approved ?? 0) +
            (accounting?.payorder?.exported ?? 0);

        const accountingOperationsTotal = salesTotal + payreqTotal + payorderTotal;
        const accountingTotal = accountingOperationsTotal;

        return {
            accounting: accountingTotal,
            accountingOperations: accountingOperationsTotal,
            accountingOperationsPayorder: payorderTotal,
            accountingOperationsPayorderPending: accounting?.payorder?.pending ?? 0,
            accountingOperationsPayorderApproved: accounting?.payorder?.approved ?? 0,
            accountingOperationsPayorderExported: accounting?.payorder?.exported ?? 0,
            accountingOperationsSales: salesTotal,
            accountingOperationsSalesUnidentifiedPayments: accounting?.sales?.unidentifiedPayments ?? 0,
            accountingOperationsSalesUninvoicedItems: accounting?.sales?.uninvoicedItems ?? 0,
            accountingOperationsPayreq: payreqTotal,
            accountingOperationsPayreqPending: accounting?.payreq?.pending ?? 0,
        };
    }

    private mapCustomersNotifications(customer: NotificationsResponseCustomer): NotificationsCustomers {
        const similarCustomers = customer?.similar_customers ?? 0;
        const customerTotal = similarCustomers;

        return {
            customers: customerTotal,
            customersSimilarCustomers: similarCustomers,
        };
    }

    private mapHomeNotifications(home: NotificationsResponseHome): NotificationsHome {
        const tasksTotal = home?.tasks?.pending ?? 0;

        const total = tasksTotal;

        return {
            home: total,
            homePendingTasks: home?.tasks?.pending ?? 0,
        };
    }

    private mapEmailSmsNotifications(emailSms: NotificationsResponseEmailSms): NotificationsEmailSms {
        const autorespondersTotal = (emailSms?.history?.errors ?? 0) + (emailSms?.moderation?.messages ?? 0);

        const total = autorespondersTotal;

        return {
            emailSms: total,
            emailSmsAutoresponder: autorespondersTotal,
            emailSmsAutoresponderHistoryErrors: emailSms?.history?.errors ?? 0,
            emailSmsAutoresponderModerationMessages: emailSms?.moderation?.messages ?? 0,
        };
    }
}
