<mat-expansion-panel
    [expanded]="initWithMenusExpanded"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = true"
    *ngIf="menu.children && menu.children.length > 0">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <i class="{{ menu.icon }}"></i>
            <span class="title"> {{ menu.label | translate }} </span>
            <span class="badge three" *ngIf="menu.notificationLookupKey && menu.notifications && menu.notifications > 0">
                {{ menu.notifications }}
            </span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ul class="sidebar-sub-menu">
        <li class="sidemenu-item" *ngFor="let child of menu.children; trackBy: trackBySideMenuItem">
            <ng-container *ngIf="child.children && child.children.length > 0">

                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true" expanded="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="title">{{ child.label | translate }}</span>
                                <span class="badge three" *ngIf="child.notificationLookupKey && menu.notifications && menu.notifications > 0">
                                    {{ menu.notifications }}
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item" *ngFor="let item of child.children; trackBy: trackBySideMenuItem">
                                <app-menu-item [menu]="item" />
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>

            </ng-container>
            <ng-container *ngIf="!child.children || child.children.length === 0">
                <app-menu-item [menu]="child" />
            </ng-container>
        </li>
    </ul>
</mat-expansion-panel>

<ng-container *ngIf="!menu.children || menu.children.length === 0">
    <ng-container>
        <a
            *ngIf="!menu.isNg1Link"
            [routerLink]="menu.url"
            class="sidemenu-link"
            routerLinkActive="active"
            [ngClass]="{ 'custom-active': isRouteActive(menu) }"
            [routerLinkActiveOptions]="{exact: true}"
            (mouseenter)="itemHover = true"
            (mouseleave)="itemHover = false"
            (click)="menuClick()"
        >
            <div class="item">
                <span class="title">
                    @if (menu.icon) {
                        <i class="menu-icon {{ menu.icon }}"></i>
                    }
                    <span>{{ menu.label | translate }}</span>
                    <span class="favorite-group" *ngIf="menu.isFavorite && (menu.favoriteLabel && menu.favoriteLabel.length > 0)"> ({{ menu.favoriteLabel }}) </span>
                </span>
                <span class="actions">
                    <span class="badge three" *ngIf="menu.notificationLookupKey && menu.notifications && menu.notifications > 0">
                        {{ menu.notifications }}
                    </span>
                    @if (menu.url !== '/home') {
                        <div class="favorite-star">
                            <i
                                class="favorite-item"
                                [ngClass]="{ 'flaticon-star-1': displayHover, 'flaticon-star': !displayHover }"
                                (mouseenter)="displayHover = true"
                                (mouseleave)="displayHover = false"
                                *ngIf="(!menu.isFavorite && !isLoadingFavorite && itemHover)  || (isMobile && !menu.isFavorite)"
                                (click)="addFavorite($event, menu.label)"
                                aria-hidden="true"
                            ></i>
                            <i class="flaticon-star-1" *ngIf="menu.isFavorite && !isLoadingFavorite" aria-hidden="true" (click)="removeFavorite($event, menu.label)"></i>
                        </div>
                    }
                </span>
            </div>
        </a>
        <ng1-link *ngIf="menu.isNg1Link" [url]="menu.url" (click)="menuClick()"
                  (mouseenter)="itemHover = true"
                  (mouseleave)="itemHover = false">
            <div class="item">
                <span class="title">
                    <span>{{ menu.label | translate }}</span>
                    <span style="width: 15px" class="favorite-group" *ngIf="menu.isFavorite && (menu.favoriteLabel && menu.favoriteLabel.length > 0)"> ({{ menu.favoriteLabel }}) </span>
                </span>
                <span class="actions">
                    <span class="badge three" *ngIf="menu.notificationLookupKey && menu.notifications && menu.notifications > 0">
                        {{ menu.notifications }}
                    </span>

                    @if (menu.url !== '/home') {
                        <div class="favorite-star">
                            <i
                                [ngClass]="{ 'flaticon-star-1': displayHover, 'flaticon-star': !displayHover }"
                                (mouseenter)="displayHover = true"
                                (mouseleave)="displayHover = false"
                                *ngIf="(!menu.isFavorite && !isLoadingFavorite && itemHover) || (isMobile && !menu.isFavorite)"
                                (click)="addFavorite($event, menu.label)"
                                aria-hidden="true"
                            ></i>
                            <i class="flaticon-star-1" *ngIf="(menu.isFavorite && !isLoadingFavorite && itemHover) || (isMobile && menu.isFavorite)" aria-hidden="true" (click)="removeFavorite($event, menu.label)"></i>
                        </div>
                    }
                    <mat-spinner class="mat-mdc-progress-spinner" mode="indeterminate" diameter="20" *ngIf="isLoadingFavorite" />
                </span>
            </div>
        </ng1-link>
    </ng-container>
</ng-container>
