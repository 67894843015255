import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../components/popup/popup.component';
import { Overlay } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { UpgradeModule } from '@angular/upgrade/static';

@Injectable({
    providedIn: 'root',
})
export class PopupService {
    beforeCloseDialog: Subject<void> = new Subject<void>();

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public dialog: MatDialog,
        private overlay: Overlay,
        public upgrade: UpgradeModule,
    ) {}

    listen() {
        let lastUrl = '';
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                const paramsAsArray: Array<string> = Object.values(this.route.snapshot.queryParams);
                if (paramsAsArray.some(param => param.includes('backState:'))) {
                    let backState = '';
                    const backParams: { [key: string]: unknown } = {};
                    paramsAsArray.forEach(param => {
                        if (param.includes('backState:')) {
                            backState = param.split('backState:')[1];
                        }
                        if (param.includes('backStateParams:')) {
                            const backStateParamsString = param.split('backStateParams:')[1];
                            backStateParamsString.split(',').forEach(z => {
                                const aux = z.split(':');
                                backParams[aux[0]] = aux[1] === 'null' ? null : aux[1];
                            });
                        }
                    });
                    const $state = this.upgrade.$injector?.get('$state');
                    const url = $state.href(backState, backParams);
                    if (lastUrl !== url) {
                        lastUrl = url;
                        this.router.navigateByUrl(url).then();
                    }
                }
            }

            if (event instanceof NavigationEnd) {
                lastUrl = '';
                const tree = this.router.routerState.snapshot;
                const outlets = tree.root.children.map(child => child.outlet);

                const popupOutletNames = ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'p10'];
                popupOutletNames.forEach(popupOutletName => {
                    const hasDialogForThisOutletAlready = this.dialog.openDialogs.some(
                        dialog =>
                            dialog.componentInstance instanceof PopupComponent &&
                            dialog.componentInstance.outletName === popupOutletName,
                    );

                    if (outlets.includes(popupOutletName)) {
                        if (!hasDialogForThisOutletAlready) {
                            this.openPopup(popupOutletName);
                        }
                    } else {
                        this.closePopupsByOutletName(popupOutletName);
                    }
                });
            }
        });
    }

    private openPopup(popupOutletName: string) {
        const dialogRef = this.dialog.open(PopupComponent, {
            minWidth: '100dvw',
            minHeight: '100dvh',
            scrollStrategy: this.overlay.scrollStrategies.close(),
            data: {
                outletName: popupOutletName,
            },
        });
        dialogRef.beforeClosed().subscribe(() => {
            this.beforeCloseDialog.next();
            const currentUrlTree = this.router.parseUrl(this.router.url);
            if (currentUrlTree.root.children[popupOutletName]) {
                delete currentUrlTree.root.children[popupOutletName];
                currentUrlTree.queryParams = {};
                this.router.navigateByUrl(currentUrlTree).then();
            }
        });
    }

    private closePopupsByOutletName(popupOutletName: string) {
        this.dialog.openDialogs
            .filter(
                dialog =>
                    dialog.componentInstance instanceof PopupComponent &&
                    dialog.componentInstance.outletName === popupOutletName,
            )
            .forEach(dialog => dialog.close());
    }
}
