@if (isMobile) {
    <div class="home-user">
        <div class="name">
            Hello, {{ currentSession.firstName }}!
        </div>
        <div class="actions">
            <i class="fi fi-sr-eye"></i>
            <i class="fi fi-sr-building" [matMenuTriggerFor]="organizationMenuMobile"></i>
            <mat-menu class="language-menu-dropdown menu-mobile-dropdown" #organizationMenuMobile="matMenu" xPosition="before">
                <ul class="m-0">
                    <li>
                        <button type="button" class="d-flex align-items-center border-none">
                            {{ 'ESSR' | translate }}
                        </button>
                    </li>
                </ul>
            </mat-menu>

            <i class="fi fi-sr-user" [matMenuTriggerFor]="profileMenuMobile"></i>
            <mat-menu class="profile-menu-dropdown menu-mobile-dropdown" #profileMenuMobile="matMenu" xPosition="before">
                <ul class="m-0">
                    <li>
                        <ng1-link [url]="getStateAsUrl('app.profile')">
                            <i class="ri-user-3-line"></i> Profile
                        </ng1-link>
                    </li>
                    <li>
                        <a routerLink="/account">
                            <i class="ri-settings-3-line"></i> Settings
                        </a>
                    </li>
                    <li>
                        <a class="logout" aria-hidden="true" (click)="logout()">
                            <i class="ri-shut-down-line"></i> Logout
                        </a>
                    </li>
                </ul>
            </mat-menu>

        </div>
    </div>
}

<header
        class="header-area bg-white border-radius box-shadow mb-25"
        [ngClass]="{'active': isToggled, 'sticky': isSticky}"
        [class.component-dark-theme]="themeService.isDark()"
        [class.hide-sidebar]="themeService.isHideSidebar()"
        [class.dark-header]="themeService.isHeaderDark()"
        [class.card-borderd-theme]="themeService.isCardBorder()"
        [class.card-border-radius]="themeService.isCardBorderRadius()"
>
    <div class="d-md-flex header-row align-items-center justify-content-space-between">
        <div class="header-left-side d-flex align-items-center">
            <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}" aria-hidden="true">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>

            <div class="backdrop" *ngIf="shouldDisplayMenu" (click)="closeMenu()" aria-hidden="true"></div>

            @if ((currentModule || isHome()) && isMobile) {
                <div class="module" (click)="openModules()" aria-hidden="true">
                    <i class="fi fi-rr-menu-burger"></i>
                </div>
            }

            <form class="search-box position-relative" autocomplete="off">
                <input type="text" id="searchHeader" class="input-search d-block" [placeholder]="placeholder | translate" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (ngModelChange)="searchChange($event)" (click)="searchClick()" #searchInput>
                <button type="submit"><mat-icon [fontIcon]="'search'" /></button>
            </form>
        </div>
        <ul class="header-right-side d-flex align-items-center mt-0 mb-0">
            <li>
                <button mat-icon-button [matMenuTriggerFor]="notificationsMenu" class="btn p-0 border-none rounded-circle position-relative">
                    <i class="fi fi-rr-bell"></i>
                </button>
                <mat-menu class="language-menu-dropdown" #notificationsMenu="matMenu" xPosition="after"></mat-menu>
            </li>

            @if (documentations.length > 1) {
                <li>
                    <button mat-icon-button [matMenuTriggerFor]="documentationMenu" class="btn p-0 border-none rounded-circle position-relative">
                        <i class="fi fi-rr-interrogation"></i>
                    </button>
                    <mat-menu class="profile-menu-dropdown header-documentation-mat-menu" #documentationMenu="matMenu">
                        <ul class="m-0">
                            @for (documentation of documentations; track documentation.state) {
                                <li>
                                    <ng1-link [url]="getStateAsUrl(documentation.state, true)">
                                        {{ documentation.name }}
                                    </ng1-link>
                                </li>
                            }
                        </ul>
                    </mat-menu>
                </li>
            }
            @if (documentations.length === 1) {
                <li>
                    <ng1-link [url]="getStateAsUrl(documentations[0].state)">
                        <button mat-icon-button class="btn p-0 border-none rounded-circle position-relative">
                            <i class="fi fi-rr-interrogation"></i>
                        </button>
                    </ng1-link>
                </li>
            }

            <li>
                <button mat-icon-button [matMenuTriggerFor]="languageMenu" class="btn p-0 border-none rounded-circle position-relative">
                    @if (lang === 'dev') {
                        <i class="fi fi-rr-laptop-code dev-icon"></i>
                    } @else {
                        <img [src]="'/assets/images/flags/' + lang + '.png'" alt="lang">
                    }
                </button>
                <mat-menu class="language-menu-dropdown" #languageMenu="matMenu" xPosition="after">
                    <ul class="m-0">
                        <li *ngFor="let lang of languages">
                            <button id="language-options" type="button" class="d-flex align-items-center border-none" (click)="changeLanguage(lang.id)">
                                @if (lang.id === 'dev') {
                                    <i class="fi fi-rr-laptop-code dev-icon"></i>
                                } @else {
                                    <img [src]="'/assets/images/flags/' + lang.id + '.png'" [alt]="lang.label">
                                }
                                {{ lang.label | translate }}
                            </button>
                        </li>
                        <li>
                            <button id="language-options" class="d-flex align-items-center border-none" type="button" [routerLink]="['', { outlets: { p1: ['translate'] } }]">
                                Manage translations
                            </button>
                        </li>
                    </ul>
                </mat-menu>
            </li>
            <li class="organization-desktop">
                <button mat-icon-button [matMenuTriggerFor]="organizationMenu" class="btn p-0 border-none rounded-circle position-relative">
                    <i class="fi fi-rr-building"></i>
                </button>
                <mat-menu class="language-menu-dropdown" #organizationMenu="matMenu" xPosition="after">
                    <ul class="m-0">
                        <li>
                            <button type="button" class="d-flex align-items-center border-none">
                                {{ 'ESSR' | translate }}
                            </button>
                        </li>
                    </ul>
                </mat-menu>
            </li>
            <li class="user-desktop">
                <button mat-icon-button [matMenuTriggerFor]="profileMenu" class="profile-btn main-color border-none bg-transparent position-relative">
                    <img *ngIf="currentSession.avatarUrl" [src]="currentSession.avatarUrl" class="rounded-circle" alt="user avatar">
                    <span class="fw-semibold user-name">
                        {{ currentSession.fullName }}
                    </span>
                </button>
                <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">
                    <ul class="m-0">
                        <li>
                            <ng1-link [url]="getStateAsUrl('app.profile')">
                                <i class="ri-user-3-line"></i> Profile
                            </ng1-link>
                        </li>
                        <li>
                            <a routerLink="/account">
                                <i class="ri-settings-3-line"></i> Settings
                            </a>
                        </li>
                        <li>
                            <a class="logout" aria-hidden="true" (click)="logout()">
                                <i class="ri-shut-down-line"></i> Logout
                            </a>
                        </li>
                    </ul>
                </mat-menu>
            </li>
        </ul>
    </div>
</header>
