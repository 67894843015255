// This file is generated automatically after all Translation Seeders were executed
export default {
    commons: {
        enums: {
            modules: {
                crm: 'v2translations.commons.enums.modules.crm',
                contacts: 'v2translations.commons.enums.modules.contacts',
                core: 'v2translations.commons.enums.modules.core',
                dashboards: 'v2translations.commons.enums.modules.dashboards',
                financial: 'v2translations.commons.enums.modules.financial',
                gsearch: 'v2translations.commons.enums.modules.gsearch',
            },
            language: {
                english: 'v2translations.commons.enums.language.english',
                french: 'v2translations.commons.enums.language.french',
                german: 'v2translations.commons.enums.language.german',
            },
        },
        labels: {
            startDate: 'v2translations.commons.labels.startDate',
            all: 'v2translations.commons.labels.all',
            apply: 'v2translations.commons.labels.apply',
            attachment: 'v2translations.commons.labels.attachment',
            attachments: 'v2translations.commons.labels.attachments',
            error: 'v2translations.commons.labels.error',
            loading: 'v2translations.commons.labels.loading',
            loadMore: 'v2translations.commons.labels.loadMore',
            noDataFound: 'v2translations.commons.labels.noDataFound',
            or: 'v2translations.commons.labels.or',
            search: 'v2translations.commons.labels.search',
            selectAll: 'v2translations.commons.labels.selectAll',
            selected: 'v2translations.commons.labels.selected',
            endDate: 'v2translations.commons.labels.endDate',
            saving: 'v2translations.commons.labels.saving',
        },
        actions: {
            apply: {
                label: 'v2translations.commons.actions.apply.label',
            },
            cancel: {
                label: 'v2translations.commons.actions.cancel.label',
            },
            close: {
                label: 'v2translations.commons.actions.close.label',
            },
            continue: {
                label: 'v2translations.commons.actions.continue.label',
            },
            download: {
                label: 'v2translations.commons.actions.download.label',
            },
            next: {
                label: 'v2translations.commons.actions.next.label',
            },
            readyToSave: {
                label: 'v2translations.commons.actions.readyToSave.label',
            },
            save: {
                label: 'v2translations.commons.actions.save.label',
            },
            reset: {
                label: 'v2translations.commons.actions.reset.label',
            },
            back: {
                label: 'v2translations.commons.actions.back.label',
            },
        },
    },
    menus: {
        crm: {
            title: 'v2translations.menus.crm.title',
            children: {
                leads: {
                    title: 'v2translations.menus.crm.children.leads.title',
                    children: {
                        leads: {
                            title: 'v2translations.menus.crm.children.leads.children.leads.title',
                        },
                    },
                },
                dashboard: {
                    title: 'v2translations.menus.crm.children.dashboard.title',
                },
            },
        },
        core: {
            children: {
                dashboards: {
                    title: 'v2translations.menus.core.children.dashboards.title',
                    children: {
                        tasks: {
                            title: 'v2translations.menus.core.children.dashboards.children.tasks.title',
                        },
                    },
                },
                favorites: {
                    title: 'v2translations.menus.core.children.favorites.title',
                },
                home: {
                    title: 'v2translations.menus.core.children.home.title',
                },
            },
        },
        financial: {
            title: 'v2translations.menus.financial.title',
            children: {
                dashboard: {
                    title: 'v2translations.menus.financial.children.dashboard.title',
                },
                purchases: {
                    title: 'v2translations.menus.financial.children.purchases.title',
                    children: {
                        purchase: {
                            title: 'v2translations.menus.financial.children.purchases.children.purchase.title',
                        },
                        management: {
                            title: 'v2translations.menus.financial.children.purchases.children.management.title',
                            children: {
                                suppliers: {
                                    title: 'v2translations.menus.financial.children.purchases.children.management.children.suppliers.title',
                                },
                                services: {
                                    title: 'v2translations.menus.financial.children.purchases.children.management.children.services.title',
                                },
                                products: {
                                    title: 'v2translations.menus.financial.children.purchases.children.management.children.products.title',
                                },
                            },
                        },
                    },
                },
                sales: {
                    title: 'v2translations.menus.financial.children.sales.title',
                    children: {
                        quotes: {
                            title: 'v2translations.menus.financial.children.sales.children.quotes.title',
                        },
                        sales: {
                            title: 'v2translations.menus.financial.children.sales.children.sales.title',
                        },
                        serviceOrders: {
                            title: 'v2translations.menus.financial.children.sales.children.serviceOrders.title',
                        },
                        management: {
                            title: 'v2translations.menus.financial.children.sales.children.management.title',
                            children: {
                                customers: {
                                    title: 'v2translations.menus.financial.children.sales.children.management.children.customers.title',
                                },
                                services: {
                                    title: 'v2translations.menus.financial.children.sales.children.management.children.services.title',
                                },
                                products: {
                                    title: 'v2translations.menus.financial.children.sales.children.management.children.products.title',
                                },
                                shipping: {
                                    title: 'v2translations.menus.financial.children.sales.children.management.children.shipping.title',
                                },
                            },
                        },
                    },
                },
                accounting: {
                    title: 'v2translations.menus.financial.children.accounting.title',
                    children: {
                        cashFlow: {
                            title: 'v2translations.menus.financial.children.accounting.children.cashFlow.title',
                        },
                        accountsPayable: {
                            title: 'v2translations.menus.financial.children.accounting.children.accountsPayable.title',
                        },
                        accountsReceivable: {
                            title: 'v2translations.menus.financial.children.accounting.children.accountsReceivable.title',
                        },
                        transactions: {
                            title: 'v2translations.menus.financial.children.accounting.children.transactions.title',
                        },
                        management: {
                            title: 'v2translations.menus.financial.children.accounting.children.management.title',
                            children: {
                                bankAccounts: {
                                    title: 'v2translations.menus.financial.children.accounting.children.management.children.bankAccounts.title',
                                },
                            },
                        },
                    },
                },
                stock: {
                    title: 'v2translations.menus.financial.children.stock.title',
                    children: {
                        inventory: {
                            title: 'v2translations.menus.financial.children.stock.children.inventory.title',
                        },
                        management: {
                            title: 'v2translations.menus.financial.children.stock.children.management.title',
                            children: {
                                products: {
                                    title: 'v2translations.menus.financial.children.stock.children.management.children.products.title',
                                },
                                measurementUnits: {
                                    title: 'v2translations.menus.financial.children.stock.children.management.children.measurementUnits.title',
                                },
                            },
                        },
                    },
                },
            },
        },
        schooldrive: {
            title: 'v2translations.menus.schooldrive.title',
            children: {
                tasks: {
                    title: 'v2translations.menus.schooldrive.children.tasks.title',
                    children: {
                        dashboards: {
                            title: 'v2translations.menus.schooldrive.children.tasks.children.dashboards.title',
                        },
                        myTasks: {
                            title: 'v2translations.menus.schooldrive.children.tasks.children.myTasks.title',
                        },
                        allTasks: {
                            title: 'v2translations.menus.schooldrive.children.tasks.children.allTasks.title',
                        },
                    },
                },
            },
        },
    },
    crm: {
        title: 'v2translations.crm.title',
        pages: {
            leads: {
                title: 'v2translations.crm.pages.leads.title',
                labels: {
                    id: 'v2translations.crm.pages.leads.labels.id',
                    title: 'v2translations.crm.pages.leads.labels.title',
                    email: 'v2translations.crm.pages.leads.labels.email',
                    firstName: 'v2translations.crm.pages.leads.labels.firstName',
                    lastName: 'v2translations.crm.pages.leads.labels.lastName',
                    status: 'v2translations.crm.pages.leads.labels.status',
                    product: 'v2translations.crm.pages.leads.labels.product',
                },
            },
        },
    },
    core: {
        modules: {
            auth: {
                commons: {
                    actions: {
                        continueWith: {
                            label: 'v2translations.core.modules.auth.commons.actions.continueWith.label',
                        },
                        signIn: {
                            label: 'v2translations.core.modules.auth.commons.actions.signIn.label',
                        },
                        continue: {
                            label: 'v2translations.core.modules.auth.commons.actions.continue.label',
                        },
                    },
                    attributes: {
                        email: {
                            label: 'v2translations.core.modules.auth.commons.attributes.email.label',
                            placeholder: 'v2translations.core.modules.auth.commons.attributes.email.placeholder',
                        },
                        phone: {
                            label: 'v2translations.core.modules.auth.commons.attributes.phone.label',
                        },
                        password: {
                            label: 'v2translations.core.modules.auth.commons.attributes.password.label',
                        },
                    },
                    labels: {
                        email: 'v2translations.core.modules.auth.commons.labels.email',
                        phone: 'v2translations.core.modules.auth.commons.labels.phone',
                        newTo: 'v2translations.core.modules.auth.commons.labels.newTo',
                        createAccount: 'v2translations.core.modules.auth.commons.labels.createAccount',
                        rememberMe: 'v2translations.core.modules.auth.commons.labels.rememberMe',
                        alreadyUsing: 'v2translations.core.modules.auth.commons.labels.alreadyUsing',
                    },
                },
                pages: {
                    createAccount: {
                        title: 'v2translations.core.modules.auth.pages.createAccount.title',
                        labels: {
                            signInToExistingAccount: 'v2translations.core.modules.auth.pages.createAccount.labels.signInToExistingAccount',
                        },
                    },
                    signIn: {
                        title: 'v2translations.core.modules.auth.pages.signIn.title',
                        labels: {
                            newTo: 'v2translations.core.modules.auth.pages.signIn.labels.newTo',
                            rememberMe: 'v2translations.core.modules.auth.pages.signIn.labels.rememberMe',
                            signManually: 'v2translations.core.modules.auth.pages.signIn.labels.signManually',
                            willSendEmailAdvise: 'v2translations.core.modules.auth.pages.signIn.labels.willSendEmailAdvise',
                        },
                    },
                    signInManually: {
                        title: 'v2translations.core.modules.auth.pages.signInManually.title',
                        labels: {
                            forgotPassword: 'v2translations.core.modules.auth.pages.signInManually.labels.forgotPassword',
                            signManually: 'v2translations.core.modules.auth.pages.signInManually.labels.signManually',
                            resetPassword: 'v2translations.core.modules.auth.pages.signInManually.labels.resetPassword',
                            willSendEmailAdvise: 'v2translations.core.modules.auth.pages.signInManually.labels.willSendEmailAdvise',
                        },
                    },
                    verify: {
                        title: 'v2translations.core.modules.auth.pages.verify.title',
                        attributes: {
                            verificationCode: {
                                label: 'v2translations.core.modules.auth.pages.verify.attributes.verificationCode.label',
                            },
                        },
                        actions: {
                            resend: {
                                label: 'v2translations.core.modules.auth.pages.verify.actions.resend.label',
                            },
                            verify: {
                                label: 'v2translations.core.modules.auth.pages.verify.actions.verify.label',
                            },
                        },
                        labels: {
                            alreadyUsing: 'v2translations.core.modules.auth.pages.verify.labels.alreadyUsing',
                            magicCodeSent: 'v2translations.core.modules.auth.pages.verify.labels.magicCodeSent',
                            viaSms: 'v2translations.core.modules.auth.pages.verify.labels.viaSms',
                            resendVerification: 'v2translations.core.modules.auth.pages.verify.labels.resendVerification',
                            resendVerificationTimer: 'v2translations.core.modules.auth.pages.verify.labels.resendVerificationTimer',
                        },
                        validations: {
                            code: 'v2translations.core.modules.auth.pages.verify.validations.code',
                        },
                    },
                    forgotPassword: {
                        title: 'v2translations.core.modules.auth.pages.forgotPassword.title',
                        actions: {
                            resetPassword: {
                                label: 'v2translations.core.modules.auth.pages.forgotPassword.actions.resetPassword.label',
                            },
                        },
                        labels: {
                            instruction: 'v2translations.core.modules.auth.pages.forgotPassword.labels.instruction',
                            linkSentTo: 'v2translations.core.modules.auth.pages.forgotPassword.labels.linkSentTo',
                            resend: 'v2translations.core.modules.auth.pages.forgotPassword.labels.resend',
                            didntReceiveLink: 'v2translations.core.modules.auth.pages.forgotPassword.labels.didntReceiveLink',
                        },
                    },
                    resetPassword: {
                        title: 'v2translations.core.modules.auth.pages.resetPassword.title',
                        actions: {
                            submit: {
                                label: 'v2translations.core.modules.auth.pages.resetPassword.actions.submit.label',
                                successMessage: 'v2translations.core.modules.auth.pages.resetPassword.actions.submit.successMessage',
                            },
                        },
                        attributes: {
                            confirmPassword: {
                                label: 'v2translations.core.modules.auth.pages.resetPassword.attributes.confirmPassword.label',
                            },
                            password: {
                                label: 'v2translations.core.modules.auth.pages.resetPassword.attributes.password.label',
                            },
                        },
                        validations: {
                            passwordNotMatch: 'v2translations.core.modules.auth.pages.resetPassword.validations.passwordNotMatch',
                            passwordTooLong: 'v2translations.core.modules.auth.pages.resetPassword.validations.passwordTooLong',
                            passwordTooSmall: 'v2translations.core.modules.auth.pages.resetPassword.validations.passwordTooSmall',
                        },
                    },
                    unauthorized: {
                        title: 'v2translations.core.modules.auth.pages.unauthorized.title',
                        labels: {
                            missingTranslation: 'v2translations.core.modules.auth.pages.unauthorized.labels.missingTranslation',
                        },
                    },
                },
            },
            header: {
                commons: {
                    labels: {
                        searchFieldPlaceholderDesktop: 'v2translations.core.modules.header.commons.labels.searchFieldPlaceholderDesktop',
                        searchFieldPlaceholderMobile: 'v2translations.core.modules.header.commons.labels.searchFieldPlaceholderMobile',
                    },
                },
            },
            table: {
                commons: {
                    labels: {
                        itemsPerPageLabel: 'v2translations.core.modules.table.commons.labels.itemsPerPageLabel',
                        resultCount: 'v2translations.core.modules.table.commons.labels.resultCount',
                        search: 'v2translations.core.modules.table.commons.labels.search',
                    },
                },
            },
            timeline: {
                commons: {
                    actions: {
                        expandAll: {
                            label: 'v2translations.core.modules.timeline.commons.actions.expandAll.label',
                        },
                        reverseOrder: {
                            label: 'v2translations.core.modules.timeline.commons.actions.reverseOrder.label',
                        },
                    },
                },
            },
            translations: {
                title: 'v2translations.core.modules.translations.title',
            },
            listingPage: {
                commons: {
                    attributes: {
                        name: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.name.label',
                        },
                        visibleColumns: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.visibleColumns.label',
                        },
                        order: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.order.label',
                        },
                        sharedWith: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.sharedWith.label',
                        },
                        allUsers: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.allUsers.label',
                        },
                        roles: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.roles.label',
                        },
                        users: {
                            label: 'v2translations.core.modules.listingPage.commons.attributes.users.label',
                        },
                    },
                    labels: {
                        unsavedChangesDetected: 'v2translations.core.modules.listingPage.commons.labels.unsavedChangesDetected',
                        standardViewCantCustomize: 'v2translations.core.modules.listingPage.commons.labels.standardViewCantCustomize',
                        createNewViews: 'v2translations.core.modules.listingPage.commons.labels.createNewViews',
                    },
                },
            },
            dateRangeSelectorWithDataPreview: {
                commons: {
                    labels: {
                        dateUnitDay: 'v2translations.core.modules.dateRangeSelectorWithDataPreview.commons.labels.dateUnitDay',
                        dateUnitMonth: 'v2translations.core.modules.dateRangeSelectorWithDataPreview.commons.labels.dateUnitMonth',
                    },
                },
            },
        },
    },
    dashboards: {
        title: 'v2translations.dashboards.title',
        commons: {
            actions: {
                customizeDashboard: {
                    label: 'v2translations.dashboards.commons.actions.customizeDashboard.label',
                },
            },
            enums: {
                widget_collections: {
                    dashboard_generic: 'v2translations.dashboards.commons.enums.widget_collections.dashboard_generic',
                },
                widgets: {
                    static_text: 'v2translations.dashboards.commons.enums.widgets.static_text',
                },
            },
        },
        pages: {
            dashboards: {
                title: 'v2translations.dashboards.pages.dashboards.title',
            },
        },
    },
    gSearch: {
        commons: {
            labels: {
                results: 'v2translations.gSearch.commons.labels.results',
                totalItemsDisplay: 'v2translations.gSearch.commons.labels.totalItemsDisplay',
            },
            actions: {
                showMore: {
                    label: 'v2translations.gSearch.commons.actions.showMore.label',
                },
            },
        },
    },
    schooldrive: {
        title: 'v2translations.schooldrive.title',
        modules: {
            course: {
                title: 'v2translations.schooldrive.modules.course.title',
                pages: {
                    createCourse: {
                        title: 'v2translations.schooldrive.modules.course.pages.createCourse.title',
                        labels: {
                            title: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.title',
                            selectCourseCategory: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.selectCourseCategory',
                            courseCategory: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.courseCategory',
                            doYouWantToCreate: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.doYouWantToCreate',
                            courseScheduleType: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.courseScheduleType',
                            weekCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.weekCourse',
                            weekendCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.weekendCourse',
                            coursePlace: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.coursePlace',
                            blockScheduler: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.blockScheduler',
                            calendar: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.calendar',
                            calendarBlockScheduler: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.calendarBlockScheduler',
                            sensiVDCourseHourInfoCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.sensiVDCourseHourInfoCourse',
                            sensiGECourseHourInfoCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.sensiGECourseHourInfoCourse',
                            samaCourseHourInfoWeekCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.samaCourseHourInfoWeekCourse',
                            samaCourseHourInfoWeekendCourse: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.samaCourseHourInfoWeekendCourse',
                            selectCourseScheduledType: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.selectCourseScheduledType',
                            instructions: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.instructions',
                            reviewCourseInformation: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.reviewCourseInformation',
                            selectCoursePlace: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.selectCoursePlace',
                            options: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.options',
                            daysOff: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.daysOff',
                            otherCoursesInTheSamePlace: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.otherCoursesInTheSamePlace',
                            dateBlockSelect: 'v2translations.schooldrive.modules.course.pages.createCourse.labels.dateBlockSelect',
                        },
                    },
                },
            },
            tasks: {
                title: 'v2translations.schooldrive.modules.tasks.title',
                pages: {
                    allTasks: {
                        title: 'v2translations.schooldrive.modules.tasks.pages.allTasks.title',
                    },
                    dashboard: {
                        title: 'v2translations.schooldrive.modules.tasks.pages.dashboard.title',
                        enums: {
                            widget_collections: {
                                schooldrive_tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widget_collections.schooldrive_tasks',
                            },
                            widgets: {
                                doable_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.doable_tasks_count',
                                overdue_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.overdue_tasks_count',
                                due_today_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.due_today_tasks_count',
                                upcoming_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.upcoming_tasks_count',
                                open_tasks_count: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.open_tasks_count',
                                task_progress: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.task_progress',
                                completed_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.completed_tasks_by_queue',
                                open_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.open_tasks_by_queue',
                                overdue_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.overdue_tasks_by_queue',
                                list_overdue_tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.list_overdue_tasks',
                                completed_tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.completed_tasks',
                                completed_tasks_history: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.completed_tasks_history',
                                cancelled_tasks: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.cancelled_tasks',
                                cancelled_tasks_by_queue: 'v2translations.schooldrive.modules.tasks.pages.dashboard.enums.widgets.cancelled_tasks_by_queue',
                            },
                        },
                        labels: {
                            addWidgets: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.addWidgets',
                            settings: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.settings',
                            default: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.default',
                            properties: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.properties',
                            preview: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.preview',
                            sharedWith: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.sharedWith',
                            customize: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.customize',
                            title: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.title',
                            filters: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.filters',
                            dontUse: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.dontUse',
                            static: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.static',
                            dynamic: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.dynamic',
                            unsavedChangesDetected: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.unsavedChangesDetected',
                            standardViewCantCustomize: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.standardViewCantCustomize',
                            createNewViews: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.createNewViews',
                            editMode: 'v2translations.schooldrive.modules.tasks.pages.dashboard.labels.editMode',
                        },
                        attributes: {
                            allUsers: {
                                label: 'v2translations.schooldrive.modules.tasks.pages.dashboard.attributes.allUsers.label',
                            },
                            users: {
                                label: 'v2translations.schooldrive.modules.tasks.pages.dashboard.attributes.users.label',
                            },
                            roles: {
                                label: 'v2translations.schooldrive.modules.tasks.pages.dashboard.attributes.roles.label',
                            },
                            name: {
                                label: 'v2translations.schooldrive.modules.tasks.pages.dashboard.attributes.name.label',
                            },
                        },
                    },
                    myTasks: {
                        title: 'v2translations.schooldrive.modules.tasks.pages.myTasks.title',
                        labels: {
                            finishedTodayCount: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.finishedTodayCount',
                            goodJob: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.goodJob',
                            inQueueCount: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.inQueueCount',
                            noTasksFoundInYourQueue: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.noTasksFoundInYourQueue',
                            queues: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.queues',
                            tasks: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.tasks',
                            updatedTodayCount: 'v2translations.schooldrive.modules.tasks.pages.myTasks.labels.updatedTodayCount',
                        },
                        actions: {
                            nextTask: {
                                label: 'v2translations.schooldrive.modules.tasks.pages.myTasks.actions.nextTask.label',
                            },
                        },
                    },
                },
            },
        },
    },
};
