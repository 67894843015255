import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

@Component({
    selector: 'popup',
    standalone: false,
    templateUrl: './popup.component.html',
    styleUrl: './popup.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupComponent implements OnInit {
    outletName = 'p1';
    popupClass = 'centered';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { outletName: string },
        public route: ActivatedRoute,
        public router: Router,
    ) {}

    ngOnInit() {
        this.outletName = this.data.outletName;
        const availableChildren = this.router.routerState.snapshot.root.children.find(
            child => child.routeConfig?.outlet === this.outletName,
        );
        if (!availableChildren) return;
        const currentActivatedRouteSnapshotOnSpecifiedOutlet = this.getActivatedRouteSnapshot(availableChildren);
        if (currentActivatedRouteSnapshotOnSpecifiedOutlet.data['popup-class']) {
            this.popupClass = currentActivatedRouteSnapshotOnSpecifiedOutlet.data['popup-class'];
        }
    }

    private getActivatedRouteSnapshot(availableChildren: ActivatedRouteSnapshot) {
        if (availableChildren?.children.length > 0) {
            return this.getActivatedRouteSnapshot(availableChildren.children[0]);
        }
        return availableChildren;
    }
}
