import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../types';

@Component({
    selector: 'sd-dialog-message',
    templateUrl: './sd-dialog-message.component.html',
    styleUrls: ['./sd-dialog-message.component.scss'],
})
export class SdDialogMessageComponent implements AfterViewInit {
    @ViewChild('btnRef') buttonRef?: MatButton;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.buttonRef) {
                this.buttonRef.focus();
            }
        }, 200);
    }
}
