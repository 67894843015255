import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { LocationMonitorService } from '../../../../services/location-monitor.service';
import { GSearchService } from 'resources/assets/angular/app/g-search/services/g-search.service';
import { MenuItem, MenuService } from 'resources/assets/angular/app/layout/services/menu.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ng1-link',
    styleUrls: ['./ng1-link.scss'],
    template: `
        <a class="sidemenu-link" [ngClass]="{ active: isActive }" [href]="url">
            <ng-content></ng-content>
        </a>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Ng1LinkComponent implements OnChanges, OnInit, OnDestroy {
    constructor(
        public locationMonitorService: LocationMonitorService,
        public menuService: MenuService,
        public gSearchService: GSearchService,
        public cdr: ChangeDetectorRef,
    ) {}

    @Input() canBeActive = true;
    @Input({ required: true }) url!: string;
    @Input() params: { [key: string]: string | number | null | boolean } | undefined = {};
    isActive = false;
    /* using to check dynamic */

    locationsRef!: Subscription;

    ngOnInit() {
        const routeData = this.menuService.findMenuByUrl(this.url);

        if (!routeData) return;
        this.setIsActiveStatus(routeData);

        let lastPath = window.location.pathname;
        this.locationsRef = this.locationMonitorService.isLastPath$.subscribe(newPath => {
            if (newPath !== '/') {
                if (lastPath != newPath) {
                    this.gSearchService.close();
                }
                lastPath = newPath;
                this.setIsActiveStatus(routeData);
            }
        });
    }

    setIsActiveStatus(menuData: MenuItem) {
        const isActive = this.isActive;
        if (menuData.ng1ActiveRouteRegexs) {
            this.isActive = menuData.ng1ActiveRouteRegexs.some(regex =>
                new RegExp(regex).test(window.location.pathname),
            );
        } else {
            this.isActive = false;
        }

        if (this.url === window.location.pathname) {
            this.isActive = true;
        }

        if (!this.canBeActive) {
            this.isActive = false;
        }

        if (this.isActive !== isActive) {
            this.cdr.detectChanges();
        }
    }

    ngOnChanges() {
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        this.locationsRef?.unsubscribe();
    }
}
