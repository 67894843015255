import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { FavoritesResponse } from '../types';

@Injectable({
    providedIn: 'root',
})
export class FavoritesService {
    private favorites = new BehaviorSubject<string[]>([]);
    favorites$ = this.favorites.asObservable();

    constructor(public apollo: Apollo, public http: HttpClient) {}

    getFavorites(): Observable<string[]> {
        const getFavorites = `
            query getFavorites {
                results: CORE_UserPrefMenuFavorite(where: {}) {
                    id
                    menuKey
                }
            }`;

        return this.apollo
            .watchQuery<FavoritesResponse>({
                query: gql(getFavorites),
            })
            .valueChanges.pipe(
                map(result => {
                    const favorites = result.data.results.map(z => z.menuKey);
                    this.favorites.next(favorites);
                    return favorites;
                }),
                catchError(error => throwError(() => new Error(error))),
            );
    }

    addFavorite(menuKey: string) {
        const favorites = this.favorites.value;
        favorites.push(menuKey);
        this.favorites.next(favorites);
        const module = this.getModule(menuKey);
        return this.http
            .post('/api/user-pref/menu-favorite', { menuKey, module })
            .pipe(catchError(error => throwError(() => new Error(error))));
    }

    removeFavorite(menuKey: string) {
        const favorites = this.favorites.value;
        this.favorites.next(favorites.filter(z => z !== menuKey));
        return this.http
            .delete(`/api/user-pref/menu-favorite/${menuKey}`)
            .pipe(catchError(error => throwError(() => new Error(error))));
    }

    private getModule(menuKey: string): string {
        return menuKey.split('.')[3];
    }
}
