<div class="backdrop" *ngIf="shouldDisplayMenu" (click)="closeMenu()" aria-hidden="true"></div>
<div
        class="sidebar-area bg-white box-shadow"
        [ngClass]="{'active': isToggled}"
        [class.dark-sidebar]="themeService.isSidebarDark()"
        [class.hide-sidebar]="themeService.isHideSidebar()"
        (mouseenter)="mouseOnAsideMenuChangeEvent(true)"
        (mouseleave)="mouseOnAsideMenuChangeEvent(false)"
>
    <div class="logo bg-white">
        <ng1-link *ngIf="firstPageIsNg1Link" [canBeActive]="false" [url]="firstPageUrl ?? ''" class="d-flex align-items-center">
            <img [src]="logoPath" alt="Logo">
        </ng1-link>

        <a *ngIf="!firstPageIsNg1Link" [routerLink]="firstPageUrl" class="d-flex align-items-center">
            <img [src]="logoPath" alt="Logo">
        </a>
    </div>
    <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}" aria-hidden="true">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>
    <ng-scrollbar #ngScrollbar visibility="hover" style="height: 100vh;">
        <div class="sidebar-inner">
            <div class="sidebar-menu">

                <mat-accordion [multi]="true">
                    <ng-container *ngFor="let module of modules; trackBy: trackByModule">
                        <span class="sub-title gray-color" *ngIf="moduleDisplayName(module)">
                            {{ 'module.' + module | translate }}
                        </span>
                        <ng-container *ngFor="let menu of menuItemsByModule[module]; trackBy: trackByMenu">

                            <app-menu-item [menu]="menu" [initWithMenusExpanded]="initWithMenusExpanded"/>

                        </ng-container>
                    </ng-container>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>
