import {
    Component,
    DestroyRef,
    DoCheck,
    Injector,
    OnInit,
    runInInjectionContext,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { MenuToggleService } from './layout/services/menu-toggle.service';
import { ThemeService } from './layout/services/theme.service';
import { AuthService } from '@auth/services/auth.service';
import { LocationMonitorService } from './core/modules/shared/services/location-monitor.service';
import { NotificationService } from './core/modules/notifications/services/notification.service';
import { BreakpointService } from './layout/services/breakpoint.service';
import { PopupService } from './layout/modules/popup/services/popup.service';
import { FullPageDrawerService } from './layout/services/full-page-drawer.service';
import { MatDrawer } from '@angular/material/sidenav';
import { MenuService } from './layout/services/menu.service';
import { ResponsiveNavigationService } from './layout/modules/responsive-navigation/services/responsive-navigation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthStorageService } from '@auth/services/auth-storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { ActiveModulesService } from '@shared/active-modules.service';
import { ResponsiveNavigationSwipeService } from './layout/modules/responsive-navigation/services/responsive-navigation-swipe.service';
import { BrowserService } from './layout/services/browser.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, DoCheck {
    @ViewChild(MatDrawer) drawer!: MatDrawer;

    isToggled = false;
    isReady = false;
    isLegacyAngularReady = new BehaviorSubject<boolean>(false);
    isPopup = false;
    isMobile = false;
    displayFooter = false;
    initLoadLegacyAngular = false;

    drawerTemplate: TemplateRef<unknown> | null = null;

    constructor(
        public router: Router,
        private toggleService: MenuToggleService,
        public themeService: ThemeService,
        private upgrade: UpgradeModule,
        public authService: AuthService,
        public locationMonitorService: LocationMonitorService,
        public notificationService: NotificationService,
        public breakpointService: BreakpointService,
        public detailsPageService: PopupService,
        public fullPageDrawerService: FullPageDrawerService,
        public menuService: MenuService,
        public rns: ResponsiveNavigationService,
        public authStorageService: AuthStorageService,
        public injector: Injector,
        public _snackBar: MatSnackBar,
        public destroyRef: DestroyRef,
        public activeModules: ActiveModulesService,
        public swipeService: ResponsiveNavigationSwipeService,
        public browserService: BrowserService,
        public bottomSheet: MatBottomSheet,
        public dialog: MatDialog,
    ) {
        if (this.browserService.isSafari()) {
            document.body.classList.add('safari');
        }

        this.setInitialLang();

        if (localStorage.getItem('authStorage')) {
            let storage = localStorage.getItem('authStorage');
            if (!storage) {
                storage = 'local';
            }
            this.authStorageService.setStorage((storage as 'local') || 'session');
        }

        this.detailsPageService.listen();

        const savedStatus = localStorage.getItem('sidebarStatus');
        if (savedStatus && savedStatus === 'true') {
            this.toggleService.toggle();
        }

        this.fullPageDrawerService.template$.subscribe(template => (this.drawerTemplate = template));
        this.fullPageDrawerService.openDrawer$.subscribe(() => {
            if (this.dialog.openDialogs.length > 0) return;
            if (this.bottomSheet._openedBottomSheetRef?.instance) return;
            if (this.drawerTemplate) {
                this.drawer.open().then();
            }
        });

        this.breakpointService.isMobile$.subscribe(val => (this.isMobile = val));
        this.breakpointService.init();

        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.drawer?.close().then();
            }

            if (event instanceof NavigationEnd) {
                if (!this.isBlankPage()) {
                    this.activeModules.listen();
                }
            }
        });

        this.authService.userHasLegacyAngular.subscribe(status => {
            if (status) {
                this.loadLegacyAngular();
            }
        });

        this.locationMonitorService.watch();
        this.notificationService.listen();
    }

    ngOnInit() {
        this.swipeService.swipeRight$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            if (this.isMobile && !this.isBlankPage()) {
                if (window.location.pathname.includes('sd1')) {
                    const item = document.getElementsByClassName('new-angular-content').item(0);
                    if (item) {
                        item.classList.add('full-size-modules');
                    }
                }

                this.rns.openModulesDrawer();
                this.drawer.closedStart.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
                    if (window.location.pathname.includes('sd1')) {
                        const item = document.getElementsByClassName('new-angular-content').item(0);
                        if (item) {
                            item.classList.remove('full-size-modules');
                        }
                    }
                });
            }
        });

        this.isPopup = window !== top;
        if (this.isBlankPage()) {
            this.isReady = true;
        } else {
            this.authService.currentSession.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(currentSession => {
                if (currentSession.hasLegacyAngular) {
                    this.isLegacyAngularReady.subscribe(status => (this.isReady = status));
                    this.loadLegacyAngular();
                } else {
                    this.isReady = true;
                }
            });
            this.authService.me(false);
        }
    }

    isBlankPage() {
        const blankPages = ['/forgot-password', '/create-account', '/sign-in', '/verify'];
        let url = this.router.url;
        if (url === '/' || url.length === 0 || url === '/temp-empty') {
            url = window.location.pathname;
        }
        const isBlankPage = blankPages.some(path => url.startsWith(path));

        if (!this.menuService.init && !isBlankPage) {
            this.displayFooter = url === '/crm' || url === '/financial' || url.includes('/nav');
            this.menuService.loadRoutes();
        }

        if (!isBlankPage) {
            this.rns.listen();
        }

        if (!sessionStorage.getItem('token') && !localStorage.getItem('token')) {
            return true;
        }

        return isBlankPage;
    }

    isHome() {
        let url = this.router.url;

        if (!url) {
            url = window.location.pathname;
        }
        return url === '/home';
    }

    setInitialLang() {
        if (!localStorage.getItem('lang')) {
            let lang = navigator.language;
            if (lang.includes('-')) {
                lang = lang.split('-')[0];
            }
            const allowedLanguages = ['en', 'fr'];
            lang = allowedLanguages.includes(lang) ? lang : 'en';
            localStorage.setItem('lang', lang);
        }
    }

    /**
     * Loads the legacy AngularJS scripts and initializes the application.
     * This method fetches the AngularJS partials script, modifies it to adapt
     * to the current environment, and then loads the legacy AngularJS module.
     */
    loadLegacyAngular() {
        if (this.initLoadLegacyAngular) {
            this.isLegacyAngularReady.next(true);
            return;
        }
        this.initLoadLegacyAngular = true;

        // Set the base assets URL if it's not already defined.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const windowRef = window as any;
        if (!windowRef.base_assets_href) {
            windowRef.base_assets_href = '/';
        }

        this._snackBar.open('Loading legacy pages', 'Close', { duration: 100000 });
        // Fetch the AngularJS partials script from the specified URL.
        fetch(windowRef.base_assets_href + 'angularjs-partials.js')
            .then(response => response.text())
            .then(scriptContent => {
                // Create a promise that resolves when the partials are loaded.
                const partialsPromise = new Promise(res => {
                    windowRef.partialsPromiseResolve = res;
                });

                // Modify the script content to integrate with the current environment.
                let modifiedScriptContent = scriptContent;

                [
                    "angular.module('app').run(['$templateCache', ", // development
                    'angular.module("app").run(["$templateCache",', // production (minified)
                ].forEach(findString => {
                    modifiedScriptContent = modifiedScriptContent
                        /* script begin */
                        .replace(
                            findString,
                            `
                                    const $templateCache = new Map();
                                    $templateCache.put = $templateCache.set;
                                    window.templateCache = $templateCache;
                                    const tempLoadPartials = `,
                        );
                });

                modifiedScriptContent = modifiedScriptContent
                    /* script end */
                    .replace(
                        '}]);',
                        '}\n' +
                            `
                tempLoadPartials(window.templateCache);
                console.log('Partials loaded (from within the script)');
                window.partialsPromiseResolve();
            `,
                    );

                // Create a new script tag with the modified content.
                const modified_script_tag = document.createElement('script');
                modified_script_tag.text = modifiedScriptContent;

                // When the partials are loaded, initialize AngularJS.
                partialsPromise.then(() => {
                    console.log('Modified partials script loaded, initializing AngularJs');

                    // Loads angularJs
                    setTimeout(() => {
                        import('./schooldrive-legacy-angular/schooldrive-legacy-angular-module.module').then(module => {
                            runInInjectionContext(this.injector, () => {
                                // Bootstrap the module to make its providers available
                                const moduleInjector = Injector.create({
                                    providers: [
                                        {
                                            provide: module.SchooldriveLegacyAngularModuleModule,
                                            useClass: module.SchooldriveLegacyAngularModuleModule,
                                        },
                                    ],
                                    parent: this.injector,
                                });

                                // Get an instance of the module and mark AngularJS as ready.
                                moduleInjector.get(module.SchooldriveLegacyAngularModuleModule);
                                this.authService.setLegacyAngularAuth();
                                this._snackBar.dismiss();
                                this.isLegacyAngularReady.next(true);
                            });
                        });
                    }, 0);
                });

                // Append the modified script tag to the document head
                document.head.appendChild(modified_script_tag);
            })
            .catch(error => {
                console.error('Failed to load or modify the partials script:', error);
            });
    }

    public ngDoCheck(): void {
        this.synchronizeBrowserUrlWithAngularRouterUrl();
    }

    /** Current browser URL */
    currentRelativeUrl = '';
    /** Internal variable to optimize the check */
    _previousCheckUrl = '';
    /**
     * Monitors the browser URL and compare with the known router URL.
     *
     * This is a workaround needed to react to changes because the old @uirouter/angularjs
     * prevents the new angular to get some navigation events.
     *
     */
    public synchronizeBrowserUrlWithAngularRouterUrl(): void {
        const browserLocationHref = window.location.href;
        const browserLocationOrigin = window.location.origin;

        // Avoid running the replaces if the URL didn't changed
        if (this._previousCheckUrl !== browserLocationHref) {
            this._previousCheckUrl = browserLocationHref;

            const newRelativeUrl = browserLocationHref.replace(browserLocationOrigin, '');

            // Moved from SD2->SD1 (or initial SD1 load)
            if (
                this.currentRelativeUrl.length &&
                // was sd2
                !this.currentRelativeUrl.startsWith('/sd1') &&
                // going to sd1
                newRelativeUrl.startsWith('/sd1')
            ) {
                // Navigate to the temporary empty URL (so the user can return to sd2 route avoiding the NavigationSkipped )
                this.router.navigateByUrl('/temp-empty', { skipLocationChange: true }).then(() => {
                    // After navigating to the temporary URL (in SD2), navigate to the original URL (in SD1)
                    const $location = this.upgrade.$injector.get('$location');
                    const relativeUrl = newRelativeUrl === '/' ? 'sd1/home/whats-up' : newRelativeUrl;
                    $location.path(decodeURI(relativeUrl));
                });
            }
            this.currentRelativeUrl = newRelativeUrl;
        }
    }
}
