import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactsModule } from './contacts/contacts.module';
import { LayoutModule } from './layout/layout.module';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { DataLakeEmailsModule } from './data-lake-emails/data-lake-emails.module';
import { AccountsPayableModule } from './accounts-payable/accounts-payable.module';
import TranslationsFactory from './core/modules/translations/translations.factory';
import { ApiInterceptorModule } from './core/interceptors/api-interceptor.module';
import { GSearchModule } from './g-search/g-search.module';
import ApolloOptionsFactory from '@core/factories/apollo-options.factory';
import { TagsComponent } from './core/modules/tags/components/tags/tags.component';
import { UpgradeModule } from '@angular/upgrade/static';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PipesModule } from '@shared/pipes.module';
import { ResponsiveNavigationModule } from './layout/modules/responsive-navigation/responsive-navigation.module';
import { SdDialogMessageModule } from './core/modules/shared/modules/sd-dialog-message/sd-dialog-message.module';
import { Ng2LinkModule } from './core/modules/shared/modules/ng2-link/ng2-link.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        /* angular */
        BrowserModule,
        UpgradeModule,
        BrowserAnimationsModule,
        AngularMaterialModule,
        HttpClientModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        NgScrollbarModule,
        TranslateModule.forRoot(),
        AppRoutingModule,
        /* pipes */
        PipesModule,
        /* components */
        ResponsiveNavigationModule,
        GSearchModule,
        LayoutModule,
        ContactsModule,
        TagsComponent,
        SdDialogMessageModule,
        Ng2LinkModule,

        DataLakeEmailsModule,
        AccountsPayableModule,
        ContactsModule,
    ],
    exports: [ApolloModule],
    providers: [
        TranslatePipe,
        {
            /* this run get translations function as first */
            provide: APP_INITIALIZER,
            useFactory: TranslationsFactory,
            deps: [HttpClient, TranslateService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptorModule,
            multi: true,
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: ApolloOptionsFactory,
            deps: [HttpLink],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
